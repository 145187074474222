@font-face {
    font-family: "Gerstner-Programm";
    src: local("Gerstner-ProgrammRegular"),
    url("../public/font/Gerstner-ProgrammRegular.woff2") format("woff2");
    font-weight: normal;
}

@font-face {
    font-family: "Gerstner-Programm";
    src: local("Gerstner-ProgrammMedium"),
    url("../public/font/Gerstner-ProgrammMedium.woff2") format("woff2");
    font-weight: 700;
}

@font-face {
    font-family: "Gerstner-Programm";
    src: local("Gerstner-ProgrammMedium"),
    url("../public/font/Gerstner-ProgrammMedium.woff2") format("woff2");
    font-weight: 500;
}

@font-face {
    font-family: "Gerstner-Programm";
    src: local("Gerstner-ProgrammMedium"),
    url("../public/font/Gerstner-ProgrammMedium.woff2") format("woff2");
    font-weight: 600;
}

:root {
    --space-1: 1rem;
    --space-2: 1.2rem;
    --space-3: 1.4rem;
    --space-4: 1.6rem;
    --space-5: 1.8rem;
    --space-6: 2rem;
    --white: #fff;
    --black: #000;
}

::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: #000;
}

::selection {
    color: #fff;
    background: #000;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Gerstner-Programm, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

html, body {
    font-size: 14px;
    background: var(--white);
}

html, body, #root {
    width: 100%;
    height: 100%;
    width: 100dvw;
    height: 100dvh;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: var(--space-2);
    z-index: 100;
}

header nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--space-2);
    font-size: 1rem;
}

header nav a {
    color: var(--black);
    text-decoration: none;
}

.overview {
    position: relative;
}

.projects {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    will-change: transform;
    overflow: hidden;
}

.project {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
}

.project-images {
    height: calc(100% - 90px);
    display: flex;
    flex: 1 1;
    width: auto;
}

.project-images img {
    max-height: 100%;
    width: auto;
}

.project-description {
    flex: 0 0 90px;
    background: var(--white);
    color: var(--black);
    display: flex;
    flex-direction: column;
    padding: var(--space-1);
}

@media screen and (max-width: 768px) {
    .project-images {
        height: calc(100% - 160px);
    }

    .project-description {
        flex: 0 0 160px;
    }

    .project-description p, .project-description h1 {
        max-width: calc(100vw - calc(var(--space-2) * 2));
    }
}

.project-description a {
    color: #000;
}

.project-description h1 {
    font-size: 1rem;
    font-weight: bold;
}

.project-description p {
    font-size: 1rem;
}

.active-page {
    text-decoration: underline;
}

.about-page {
    display: grid;
    gap: var(--space-3);
    grid-template-areas:
        "text photo"
        "social credits";
    grid-template-columns: 75% 35%;
    grid-template-rows: 85% 15%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.about-page a {
    text-decoration: none;
    color: var(--black);
}

.about-text {
    grid-area: text;
    padding: var(--space-3);
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.about-social {
    grid-area: social;
    padding: var(--space-3);
}

.about-photo {
    grid-area: photo;
}

.about-photo img {
    max-height: 100%;
    object-fit: contain;
}

.about-credits {
    grid-area: credits;
}

.loading-fullscreen {
    position: fixed;
    inset: 0;
    z-index: 99999999;
    background: #fff;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.loading-fullscreen h4 {
    position: relative;
    display: block;
    background: linear-gradient(90deg, #ff0000 0%, #ff0000 50%, #000000 50%, #000000 100%);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200% 100%;
    animation: loading 10s linear infinite;
    padding: var(--space-3);
}

@keyframes loading {
    from {
        background-position: 100% 0;
    }
    to {
        background-position: -100% 0;
    }
}

@media (max-width: 768px) {
    .about-page {
        grid-template-areas: 
            "text"
            "social"
            "photo"
            "credits";
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .about-text {
        padding-top: 25%;
    }

    .about-photo img {
        max-width: 100%;
    }

    .about-text h1 {
        font-size: 1.4rem;
    }

    .about-credits {
        padding: var(--space-3);
        padding-top: 0;
    }
}